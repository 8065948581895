/* General styling */
.gallery_title {
    text-align: center;
    padding-bottom: 1rem;
  }
  
  .gallery {
    margin: 0 auto;
    display: grid;
    grid-gap: 20px;
    padding-bottom: 70px;
    margin-top: 50px;
  }
  

  .taghead{
    color: white;
  }
  /* Grid for large screens (4 items per row) */
  @media screen and (min-width: 1024px) {
    .gallery {
      grid-template-columns: repeat(4, 1fr);
      grid-auto-rows: auto;
    }
  }
  
  /* Grid for small screens (1 item per row) */
  @media screen and (max-width: 768px) {
    .gallery {
      grid-template-columns: 1fr;
    }
  }
  
  .gallery .item {
    max-width: 180px;
    height: 180px;
    margin: 0 auto;
    cursor: pointer;
    filter: grayscale(40%);
  }
  
  .gallery .item:hover {
    filter: none;
    transition: 0.3s ease-out;
    transform: scale(1.06);
  }
  
  .iiimg {
    max-width: 100%; /* Ensures the image fits within the grid item */
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 10px 0px,
      rgba(0, 0, 0, 0.5) 0px 2px 15px 0px;
  }
  
  /* Placeholder text styling for small screens */
  @media screen and (max-width: 480px) {
    input::placeholder {
      font-size: 13px;
    }
  }
  