.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.heading1{
  color: black;
}

.flexbox {
  display: flex;
  color: black;
  background:rgb(253, 253, 253);
  text-align: left;
  margin-top: 20px;
  flex-wrap: wrap; /* Allows flex items to wrap on smaller screens */
  padding: 20px;
}

.fristone {
  width: 70%;
 
}

.fristtwo {
  width: 30%;
}

.h22 {
  margin-bottom: 10px;
  font-weight: 500;
}
.imagee{
  height: 400px;
  width: 90%;
  margin: auto;
  padding-left: 10px;
}

/* Media query for screens smaller than 768px (tablets and smaller devices) */
@media (max-width: 768px) {
  .fristone {
    width: 100%; /* Take full width on smaller screens */
  }

  .fristtwo {
    width: 100%; /* Take full width on smaller screens */
    margin-top: 20px; /* Add space between the two sections */
  }
}

/* Media query for screens smaller than 480px (mobile devices) */
@media (max-width: 480px) {
  .flexbox {
    padding: 0px;
  }
  .heading1{
    font-size: 30px;
    margin-bottom: 10px;
  }
  .fristone {
    padding: 20px; /* Reduce padding on smaller screens */
    width: 100%;
   text-align: justify;
  }

  .fristtwo {
    width: 100%;
    margin-top: 15px; /* Slightly reduce margin for compact look */
    padding: 10px;
  }
  .imagee{
    height: 400px;
    width: 100%;
   
  }
 
}
