
  
  .swiper-slide-active img {
    transform: scale(1); /* Zoom effect */
    opacity: 1;
  }
  
  .swiper-container {
    overflow: hidden;
  }
.home-slider{
    box-shadow: rgba(240, 46, 170, 0.4) 0px 5px, rgba(240, 46, 170, 0.3) 0px 10px, rgba(240, 46, 170, 0.2) 0px 15px, rgba(240, 46, 170, 0.1) 0px 20px, rgba(240, 46, 170, 0.05) 0px 25px;
}

  .containeraa {
    display: flex;                    
    justify-content: space-between;   
    align-items: center;              
    padding: 20px; 
    background-color: #002733;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

h1 {
    margin: 0;                        /* Remove default margin */
    font-size: 34px;                 /* Set font size */
    color: #ffffff;                     /* Set text color */
}

.btn1 {
    background-color: #007BFF;       /* Button background color */
    color: white;                    /* Button text color */
    padding: 10px 20px;              /* Button padding */
    border: none;                    /* Remove default border */
    border-radius: 5px;              /* Rounded corners for button */
    text-decoration: none;            /* Remove underline from link */
    font-size: 16px;                  /* Button font size */
    transition: background-color 0.3s; /* Smooth transition for hover */
}

.btn1:hover {
    background-color: #0056b3;       /* Darker background on hover */
}
.functionality-image{
  height: 250px;
}
.h333{
color: white;
}