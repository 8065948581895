@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&family=Nunito:wght@200;300;400;500;600;700;800;900;1000&display=swap');

:root {
  --main-color: #002733;
  --dark-color: #001925;
  --light-color: #87A4B6;
  --medium-light-color: #013747;
  --orange: #FF7A01;
  --white: #ffffff;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Nunito', sans-serif;
}

html {
  scroll-behavior: smooth;
}

body {
  background-color: var(--main-color);
}


.montserrat {
  color: white;
  font-size: 60px;
}

.form-container-main {
  min-height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 0;
}

.form-container {
  width: 70%;
  background-color: #001925;
  padding: 30px;
  display: flex;
  border-left: 5px solid var(--orange);
  clip-path: polygon(0 0, 100% 0, 100% calc(100% - 20px), calc(100% - 20px) 100%, 0 100%);
}

.form-image {
  flex-basis: 50%;
}

.model {
  height: 100%;
  width: 100%;
}

.form {
  flex-basis: 50%;
}

input, textarea {
  width: 100%;

  padding: 10px;
  margin-bottom: 20px;
  border: none;
  outline: none;
  background-color: var(--main-color);
  color: var(--light-color);
  font-weight: bold;
}

input:focus, textarea:focus {
  border-left: 5px solid var(--orange);
}

.button-container {
  display: flex;
  gap: 10px;
}

.send-button {
  flex-basis: 70%;
  background: var(--orange);
  padding: 10px;
  color: var(--dark-color);
  text-align: center;
  font-weight: bold;
  cursor: pointer;
}

.reset-button {
  flex-basis: 30%;
  background: var(--dark-color);
  color: var(--orange);
  text-align: center;
  padding: 10px;
  cursor: pointer;
}

/* Media Queries for Responsiveness */
@media (max-width: 1200px) {
  .form-container {
    width: 80%;
  }
}

@media (max-width: 800px) {
  .form-container {
    flex-direction: column;
    width: 90%;
    border-left: none; /* Remove left border on smaller screens */
  }

  .form-image {
    height: 300px; /* Adjust height for mobile */
    margin-bottom: 20px; /* Add spacing below image */
  }

  .form {
    flex-basis: 100%; /* Take full width */
  }

  .button-container {
    flex-direction: column; /* Stack buttons vertically */
    gap: 10px; /* Increase gap between buttons */
  }

  .send-button, .reset-button {
    flex-basis: 100%; /* Full width for buttons */
  }
}


.contacticon{
    display: flex;
    justify-content:space-between;
    padding-right: 20px;
    /* padding-top: 50px; */
    
}




