.course-card {
    height: auto;
    display: flex;
    align-items: flex-start;
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 20px;
    max-width: 550px;
    margin: 0px auto;
    color: white;
    background-color: #000000;
  }
  
  .course-card__content {
    flex: 1;
    margin-right: 20px;
  }
  
  .course-card__title {
    font-size: 1.8rem;
    margin-bottom: 10px;
    color:white;
  }
  
  .course-card__subtitle {
    font-size: 1.2rem;
    margin: 10px 0;
   
  }
  
  .course-card__location {
    font-size: 1rem;
    margin-bottom: 15px;
    text-align: left;
  }
  
  .course-card__description {
    margin-bottom: 20px;
    font-size: 1rem;
    text-align: left;

  }
  
  .course-card__features {
    list-style-type: none;
    padding: 0;
    text-align: left;

  }
  
  .course-card__features li {
    margin-bottom: 10px;
  }
  
  .course-card__button {
    display:inline-flex;
    background-color: #ff8d2a;
    color: #fff;
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 5px;
    margin-top: 15px;
    
  }
  
  .course-card__button:hover {
    background-color: #0056b3;
  }
  

  


  .roadmap {
  
    margin: auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    overflow:scroll;
    height: 95vh;
    background-color: black;
  }
  
  .h1 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .sectionk {
    margin: 20px 0;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
  }
  
  h2 {
    color: #333;
    margin-bottom: 10px;
  }
  
  ul {
    list-style-type: disc;
    padding-left: 20px;
  }
  
  li {
    margin: 5px 0;
  }
  



  .scrollnone{
    height: 100vh;
    overflow: hidden;
  }

/* Customize scrollbar for Chrome, Safari, and Opera */
::-webkit-scrollbar {
    width: 0px; /* Width of the scrollbar */
}

.card-front__heading{
    color: white;
}